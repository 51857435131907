<template>
  <div class="register" :class="{ 'secondary-is-open': openSecondaryPanel }">
    <div class="common-container">
      <div class="card--secondary register-description" v-if="openSecondaryPanel">
        <img class="logo" src="@/assets/images/code-clan-logo.svg" />
        <div class="tempale-container">
          <component :is="template"></component>
        </div>
      </div>
      <v-card class="register-box">
        <div class="card-title register__header">
          <div class="register__title">
            <h2 class="headline" v-if="title">{{ title }}</h2>
            <div v-if="description">{{ description }}</div>
          </div>
          <img v-show="!openSecondaryPanel" class="logo" src="@/assets/images/code-clan-logo.svg" />
        </div>
        <v-card-text>
          <div class="secondary-container"></div>
          <div>
            <router-view />
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {},
  data() {
    return {
      openSecondaryPanel: false,
      title: "",
      description: "",
      template: null,
    };
  },
  created() {
    this.initPage();
  },
  methods: {
    initPage() {
      this.title = this.$route.meta.title as string;
      this.description = this.$route.meta.description as string;
      (this.template as any) = this.$route.meta.template;
      (this.openSecondaryPanel as any) = this.template && this.template !== null;
    },
  },
  watch: {
    $route() {
      this.initPage();
    },
  },
});
</script>

<style scoped lang="scss">
.register {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  .common-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .card--secondary {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 40px 10px 20px;
      .tempale-container {
        padding: 20px;
      }
      .logo {
        height: auto;
        width: 150px;
      }
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: $c_bittersweet_2 !important;
    .logo {
      height: auto;
      width: 80px;
      margin-left: 20px;
    }
  }
}

.register-description {
  max-width: 25vw;
}

.register-box {
  min-width: 35vw;
  max-width: 35vw;
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .register-box {
    max-width: 50vw;
  }
  .register-description {
    display: none !important;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .register-box {
    max-width: 90vw;
  }
}
</style>
