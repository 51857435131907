import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VImg = _resolveComponent("VImg")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_CommitmentChart = _resolveComponent("CommitmentChart")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.userLocation)
      ? (_openBlock(), _createBlock(_component_v_card, {
          key: 0,
          class: "mb-3"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createTextVNode(" Location: " + _toDisplayString(_ctx.userLocation) + " ", 1),
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_VImg, { src: _ctx.userCountryFlag }, null, 8, ["src"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_row, { class: "m-0" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.commitmentDetails, (commitment) => {
          return (_openBlock(), _createBlock(_component_v_col, {
            cols: "12",
            lg: "6",
            key: commitment.Id
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CommitmentChart, { commitment: commitment }, null, 8, ["commitment"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ]))
}