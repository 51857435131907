<template>
  <div class="tm-item-object">
    <div class="item-details">
      <div class="item-header">
        <div class="item-sub-header">
          <div class="item-tm-detail-box">
            <Avatar :Name="commitment.Name" :Photo="commitment.PhotoUrl" class="item-tm-detail-avatar" />
            <div class="item-tm-detail-name">
              <h6>{{ commitment.Name }}</h6>
              <div class="item-tm-detail-role" v-if="commitment.JobRole && commitment.JobRole != ''">
                <v-icon class="item-tm-detail-icon" v-tooltip="commitment.JobRole">{{ commitment.JobRoleIcon }}</v-icon>
                <span class="f-body-small item-tm-detail-role-text">{{
                  commitment.JobTitle && commitment.JobTitle != "" ? commitment.JobTitle : "-"
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="commitment.IsCommitmentExpired" class="item-expired">The Commitment of this teammember is Expired</div>

      <div class="chart-container" v-if="(commitment.VmId || commitment.CommitmentTimesheetProcessing) && !commitment.IsCommitmentExpired">
        <div class="management-tm-bar-chart-header">
          <div ref="legendContainer" class="flex-fill"></div>
          <div class="security-score">
            <span class="f-body-small">Security Score</span>
            <span class="security-score-box f-body-extra-small">{{ commitment.SecurityScore }}</span>
          </div>
        </div>

        <div>
          <Bar :data="getBarChartData(commitment)" :options="barOptions" :plugins="[htmlLegendPlugin]" />
        </div>
        <div class="chart-code-issues">
          <span class="chart-code-issues-title f-body-small">Code Issues</span>
          <div
            v-for="dailyIssues in commitment.SonarqubeIssues"
            class="chart-code-issues-item f-body-extra-small"
            :class="`${dailyIssues.MainIssue.Class}`"
            :title="`${dailyIssues.MainIssue.IssuesCount} ${dailyIssues.MainIssue.Severaty} issue(s)`"
          >
            <span @click="showSonarQubeReport(commitment.VmId, dailyIssues.Date)">{{ dailyIssues.MainIssue.IssuesCount }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <v-dialog
    persistent
    v-if="showSonarQubeReportDialog"
    v-model="showSonarQubeReportDialog"
    fullscreen
    transition="dialog-bottom-transition"
    @keydown.esc="showSonarQubeReportDialog = false"
  >
    <SonarQube :vmId="selectedVmId" @close="showSonarQubeReportDialog = false" :isClient="false" :initDate="selectedSonarqubeDate"></SonarQube>
  </v-dialog>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { TeammemberWithVmDetails } from "shared-components/src/services/openApi/api";
import Avatar from "@/components/Avatar.vue";
import { Bar } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, TimeScale } from "chart.js";
import moment from "moment";
import Utils from "shared-components/src/utils/Utils";
import "chartjs-adapter-date-fns";
import SonarQube from "shared-components/src/components/Reports/SonarQube/SonarQubeReport.vue";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, TimeScale);

export default defineComponent({
  components: {
    Bar,
    Avatar,
    SonarQube,
  },
  props: ["commitment"],
  data() {
    return {
      addLoading: false,
      showNewPosition: false,
      selectedPositionId: null as null | string,
      showPositionDetail: false,
      selectedCommitmentId: null as null | string,
      showCommitmentMenu: false,
      loading: false,
      showProjectModal: false,
      showActivityWatchModal: false,
      showWazuhModal: false,
      showVdSupportModal: false,
      selectedVmId: null as string | null,
      showEpicList: false,
      showExpiredTms: false,
      showSonarQubeReportDialog: false,
      selectedSonarqubeDate: null as null | Date,
      htmlLegendPlugin: {
        id: "htmlLegend",
        afterUpdate: (chart: any) => {
          const legendContainer = this.$refs.legendContainer;
          if (legendContainer) {
            (legendContainer as any).innerHTML = "";

            const divContainer = document.createElement("div");
            divContainer.classList.add("management-tm-bar-legend-container");

            chart.legend.legendItems.forEach((legendItem: any, i: any) => {
              const div = document.createElement("div");
              div.classList.add("legend-item");
              div.onclick = () => {
                chart.setDatasetVisibility(legendItem.datasetIndex, !chart.isDatasetVisible(legendItem.datasetIndex));
                chart.update();
              };
              const boxSpan = document.createElement("span");
              boxSpan.style.background = legendItem.fillStyle;
              boxSpan.style.borderColor = legendItem.strokeStyle;
              boxSpan.style.borderWidth = legendItem.lineWidth + "px";
              boxSpan.classList.add("legend-color-box");

              const textContainer = document.createElement("p");
              textContainer.classList.add("f-body-small");
              textContainer.style.color = legendItem.fontColor;
              textContainer.style.margin = "0";
              textContainer.style.padding = "0";
              textContainer.style.textDecoration = legendItem.hidden ? "line-through" : "";
              const text = document.createTextNode(legendItem.text);
              textContainer.appendChild(text);
              div.appendChild(boxSpan);
              div.appendChild(textContainer);
              divContainer.appendChild(div);
            });
            (legendContainer as any).appendChild(divContainer);
          }
        },
      },
      codeChartData: [] as any[],
      barOptions: {
        layout: {
          padding: {
            left: 40,
            right: 40,
          },
        },
        plugins: {
          legend: {
            display: false,
            maxHeight: 45,
            labels: {
              boxWidth: 17,
              boxHeight: 12,
              useBorderRadius: true,
              borderRadius: 5,
              color: "#FFFFFF",
              font: {
                size: 12,
                weight: 500,
              },
            },
          },
          tooltip: {
            callbacks: {
              label: (context: any) => {
                if (context.raw) {
                  if (context.dataset.label == "Code") {
                    return `${context.dataset.label}: ${(this.codeChartData as any)[context.dataIndex]} Line`;
                  }
                  if (context.dataset.label == "Timesheet") {
                    const lables = ["Timesheet Epics:"];
                    this.commitment.TimesheetDetails[context.dataIndex].Epics?.forEach((item: any) => {
                      lables.push(`${Utils.formatTime(parseFloat(item.TotalSecounds))}: ${item.Name}`);
                    });

                    lables.push(`Total: ${Utils.formatTime(parseFloat(context.raw) * 3600)}`);
                    return lables;
                  }
                  return `${context.dataset.label}: ${Utils.formatTime(parseFloat(context.raw) * 3600)}`;
                }
                return "";
              },
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            suggestedMax: 16,
            min: 0,
            max: 16,
            ticks: {
              display: false,
            },
            grid: {
              display: false,
            },
          },
          x: {
            border: {
              display: false,
            },
            ticks: {
              color: "#FFFFFF",
              font: {
                size: 12,
                weight: 500,
              },
            },
            grid: {
              drawTicks: false,
              lineWidth: 2,
              color: "#6c6c6c",
            },
          },
        },
      },
    };
  },
  async mounted() {},
  methods: {
    showSonarQubeReport(vmId: string, date: string) {
      this.selectedVmId = vmId;
      this.selectedSonarqubeDate = new Date(date);
      this.showSonarQubeReportDialog = true;
    },
    openCommitmentMenu(commitment: any) {
      this.$emit("openCommitmentMenu", commitment);
    },
    getBarChartData(commitment: TeammemberWithVmDetails) {
      if (commitment.ActivityWatchDetails) {
        let labels;
        const data = commitment.ActivityWatchDetails.map((c: any) => (c.totalSecounds ?? 0) / 3600);
        const timesheetData = [] as any[];
        commitment.TimesheetDetails?.forEach((item: any) => {
          timesheetData.push(item.Epics?.reduce((acc: any, item: any) => acc + item.TotalSecounds, 0) / 3600);
        });

        const leaveData = commitment.LeaveDetails?.map((c: any) => (c.totalSecounds ?? 0) / 3600);
        const maxCodeValue = Math.max(...(commitment.Commits?.map((c: any) => c.TotalLine) ?? [0]));
        const scaleFactor = 8 / maxCodeValue;
        this.codeChartData = commitment.Commits?.map((c: any) => c.TotalLine) ?? [];
        const codeData = this.codeChartData.map((c: any) => (c ? c * scaleFactor : 0));
        const chartData = {
          labels: [] as any[],
          datasets: [] as any[],
        };
        var barBorderRadius = 3;
        if (commitment.VmId) {
          labels = commitment.ActivityWatchDetails.map((c: any) => moment(c.date).format("ddd"));
          chartData.labels = labels;
          chartData.datasets.push({
            data: data,
            label: `Activity Watch (${commitment.TimeZone})`,
            backgroundColor: "#FCFF52",
            stack: "stack 1",
            borderRadius: barBorderRadius,
            borderSkipped: false,
          });
        }

        if (commitment.CommitmentTimesheetProcessing && (commitment.TimesheetDetails || commitment.LeaveDetails)) {
          labels = commitment.TimesheetDetails?.map((c: any) => moment(c.Date).format("ddd")) ?? [];
          chartData.labels = labels;
          chartData.datasets.push({
            data: timesheetData,
            label: "Timesheet",
            backgroundColor: "#56ABFF",
            stack: "stack 2",
            borderRadius: {
              bottomLeft: barBorderRadius,
              bottomRight: barBorderRadius,
              topLeft: barBorderRadius,
              topRight: barBorderRadius,
            },
            borderSkipped: false,
          });
          chartData.datasets.push({
            data: leaveData,
            label: "Leave",
            backgroundColor: "#CDD1DE",
            stack: "stack 2",
            borderRadius: {
              topLeft: barBorderRadius,
              topRight: barBorderRadius,
            },
            borderSkipped: false,
          });
        }
        if (codeData?.find((item: any) => item > 0)) {
          chartData.datasets.push({
            data: codeData,
            label: "Code",
            backgroundColor: "#fab2b2",
            stack: "stack 3",
            borderRadius: barBorderRadius,
            borderSkipped: false,
          });
        }

        return chartData;
      }
    },
  },
});
</script>
<style lang="scss" scoped>
@import "node_modules/shared-components/assets/style/style.scss";

.item-expired {
  background-color: $c_platinum !important;
  color: $c_imperial_red;
  text-align: center;
  font-weight: 600;
  padding: 5px;
  @extend .b-r-5;
}
.tm-item-object {
  align-items: center;
  background-color: $c_jet_3;
  @extend .b-r-10;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 16px;
  border: solid 2px $c_dim_gray;

  .item-details {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .item-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item-notif-container {
        display: flex;
        gap: 12px;
        .item-tm-detail-setting {
          background-color: $c_powder_blue;
          @extend .b-r-10;
          width: 32px !important;
          height: 32px !important;
          color: $c_black;
        }
      }

      .item-sub-header {
        display: flex;
        align-items: center;
        gap: 25px;
      }
    }
  }

  .chart-container {
    padding: 0;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .management-tm-bar-chart-header {
      display: flex;
      gap: 16px;
      align-items: center;
      justify-content: center;
      .security-score {
        @extend .b-r-5;
        gap: 4px;
        display: flex;
        padding-left: 8px;
        padding-right: 4px;
        padding-top: 4px;
        padding-bottom: 4px;
        background-color: $c_onyx;
        align-items: center;
        .security-score-box {
          padding: 2px;
          background: $c_aquamarine;
          @extend .b-r-3;
          color: $c-black;
          width: 17px;
          height: 17px;
          line-height: 13px;
          text-align: center;
        }
      }
    }
    .chart-code-issues {
      display: flex;
      justify-content: space-between;
      padding-left: 4px;
      padding-top: 4px;
      padding-bottom: 4px;
      padding-right: 40px;
      background-color: $c_onyx;
      align-items: center;
      @extend .b-r-5;
      .chart-code-issues-title {
        width: 48px;
        text-align: center;
        line-height: 12px;
      }
      .chart-code-issues-item {
        flex: 1;
        text-align: center;

        span {
          cursor: pointer;
          width: 30px;
          height: 16px;
          padding: 2px;
          @extend .b-r-3;
          color: $c_black;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
        &.zero {
          span {
            background-color: $c_battleship_grey_2;
          }
        }
        &.low {
          span {
            background-color: $c_platinum;
          }
        }
        &.medium {
          span {
            background-color: $c_mindaro;
          }
        }
        &.high {
          span {
            background-color: $c_xanthous;
          }
        }
        &.critical {
          span {
            background-color: $c_bittersweet_2;
          }
        }
      }
    }
  }
  .item-tm-detail-box {
    display: flex;
    gap: 8px;
    align-items: center;
    .item-tm-detail-avatar {
      box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 29px 0px;
      width: 36px;
      height: 36px;
    }
    .item-tm-detail-name {
      display: flex;
      flex-direction: column;

      .item-tm-detail-role {
        display: flex;
        gap: 4px;
        align-items: center;
        .item-tm-detail-icon {
          font-size: 16px;
        }
        .item-tm-detail-role-text {
          color: $c_silver_2;
        }
      }
    }
  }
}
</style>
