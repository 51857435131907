<template>
  <div>
    <v-card :loading="loading">
      <v-card-title> Choose a payment method </v-card-title>
      <v-card-text>
        <InfoBox :htmlTitle="title" />

        <v-row justify="center" class="mt-4">
          <v-radio-group v-model="paymentType" inline :disabled="teamMemberInfo.PaymentMethodType != 0 && isReadonly" class="justify-item-center">
            <v-radio
              label="Cryptocurrency"
              :disabled="teamMemberInfo.PaymentMethodType != 1 && teamMemberInfo.PaymentMethodType != 0 && isReadonly"
              :value="1"
            >
              <template v-slot:label>
                <div>Cryptocurrency<span class="text-overline"> USDT-BEP20</span></div>
              </template>
            </v-radio>
            <v-radio
              label="Bank Account"
              :disabled="teamMemberInfo.PaymentMethodType != 2 && teamMemberInfo.PaymentMethodType != 0 && isReadonly"
              :value="2"
            ></v-radio>
            <v-radio
              label="Wise Account"
              :value="3"
              :disabled="teamMemberInfo.PaymentMethodType != 3 && teamMemberInfo.PaymentMethodType != 0 && isReadonly"
            ></v-radio>
            <v-radio
              label="Paypal"
              :value="4"
              :disabled="teamMemberInfo.PaymentMethodType != 4 && teamMemberInfo.PaymentMethodType != 0 && isReadonly"
            ></v-radio>
          </v-radio-group>
        </v-row>
        <v-card-subtitle class="text-center" v-if="hasActivePayrun"
          ><span class="warning-text"
            ><strong>*</strong> You can not change default payment method when you have a active payrun. ({{ activePayrunDates }})</span
          ></v-card-subtitle
        >
        <CryptoCurrencyComponent
          :paymentMethodSaveMessage="paymentMethodSaveMessage"
          @save="save"
          :cryptos="cryptoList"
          :submitting="loading"
          :hasActivePayrun="hasActivePayrun"
          v-if="paymentType === 1"
        />
        <BankAccountComponent
          :paymentMethodSaveMessage="paymentMethodSaveMessage"
          @save="save"
          :bankAccounts="bankList"
          :submitting="loading"
          :hasActivePayrun="hasActivePayrun"
          v-if="paymentType === 2"
        />
        <WiseAccountComponent
          :paymentMethodSaveMessage="paymentMethodSaveMessage"
          @save="save"
          :wiseAccounts="wiseList"
          :submitting="loading"
          :hasActivePayrun="hasActivePayrun"
          v-if="paymentType === 3"
        />
        <PaypalComponent
          :paymentMethodSaveMessage="paymentMethodSaveMessage"
          @save="save"
          :paypals="paypalList"
          :submitting="loading"
          :hasActivePayrun="hasActivePayrun"
          v-if="paymentType === 4"
        />
      </v-card-text>
    </v-card>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { Teammember } from "shared-components/src/models/Teammember";
import Utils from "shared-components/src/utils/Utils";
import CryptoCurrencyComponent from "@/components/paymentMethods/CryptoCurrency.vue";
import BankAccountComponent from "@/components/paymentMethods/BankAccount.vue";
import WiseAccountComponent from "@/components/paymentMethods/WiseAccount.vue";
import PaypalComponent from "@/components/paymentMethods/Paypal.vue";
import { CryptoCurrency } from "shared-components/src/models/CryptoCurrency";
import PaymentMethods from "shared-components/src/definitions/PaymentMethods";
import PaymentMethodService from "@/services/PaymentMethodService";
import { BankAccount } from "shared-components/src/models/BankAccount";
import { WiseAccount } from "shared-components/src/models/WiseAccount";
import { Paypal } from "shared-components/src/models/Paypal";
import { ProjectMessages } from "shared-components/src/definitions/constants";
import { PayRunApi, PayRunModel } from "shared-components/src/services/openApi/api";
import store from "@/store";
import InfoBox from "shared-components/src/components/InfoBox.vue";
var payRunApi = new PayRunApi();
export default defineComponent({
  components: {
    CryptoCurrencyComponent,
    BankAccountComponent,
    WiseAccountComponent,
    PaypalComponent,
    InfoBox,
  },
  data() {
    return {
      inline: 0,
      paymentType: 0,
      loading: false,
      startDate: null as Date | null,
      endDate: null as Date | null,
      isReadonly: true,
      cryptoList: [] as CryptoCurrency[],
      bankList: [] as BankAccount[],
      wiseList: [] as WiseAccount[],
      paypalList: [] as Paypal[],
      paymentMethodSaveMessage: "",
      teamMemberInfo: {} as Teammember,
      activePayrun: [] as PayRunModel[],
    };
  },
  watch: {
    paymentType(newVal) {
      if (this.teamMemberInfo.PaymentMethodType != newVal) {
        this.paymentMethodSaveMessage = ProjectMessages.paymentMethodMessage;
      } else {
        this.paymentMethodSaveMessage = "";
      }
      this.fetchPaymentMethod();
    },
  },
  computed: {
    title() {
      let baseText =
        "Please note that the preferred payment method can only be changed once every 6 months. " +
        "You may change or add new accounts to your preferred payment methods at any time, " +
        "provided that there is no outstanding/unpaid Payrun. <br/><br/>";

      if (this.endDate) {
        baseText += ` <span class="error-text"> * You cannot change your payment method until <strong>${this.formatDate(
          this.endDate
        )}</strong> </span>`;
      }

      return baseText;
    },
    hasActivePayrun() {
      if (this.activePayrun.length > 0) return true;
      return false;
    },
    activePayrunDates() {
      return this.activePayrun.reduce((total, item) => {
        let d = new Date(item?.FromDate?.toString() ?? "");
        return total + (d?.getMonth() + 1) + "/" + d?.getFullYear() + " ";
      }, "");
    },
  },
  methods: {
    formatDate(date: Date | null): string {
      if (date) {
        return Utils.toVsDateFormat(date);
      } else {
        return "Not set";
      }
    },
    getEndDate(sDate: any) {
      var eDate = new Date(sDate);
      eDate.setMonth(eDate.getMonth() + 6);
      return eDate;
    },
    setTeamMemberInfo(teamMemberInfo: Teammember) {
      return store.dispatch("setTeamMemberInfo", { teamMemberInfo, vm: this });
    },
    async saveCryproCurrency(model: any) {
      var findWallet = null as CryptoCurrency | null;
      if (!model.Id) {
        var wallet = this.cryptoList.find((c) => c.WalletAddress === model.WalletAddress);
        if (wallet) {
          findWallet = wallet;
        }
      }
      if (findWallet) {
        store.dispatch("showErrorMessage", ProjectMessages.cryptoDuplicateError);
      } else {
        var cryproResult = await PaymentMethodService.saveTeammemberCryptoCurrency(model);
        if (cryproResult) {
          this.cryptoList = cryproResult;
        }
      }
    },
    async saveBankAccount(model: any) {
      var findAccount = null as BankAccount | null;
      if (!model.Id) {
        var account = this.bankList.find(
          (c) =>
            ((model.Country === "Australia" && c.BSB && model.BSB && c.BSB.toLowerCase().trim() === model.BSB.toLowerCase().trim()) ||
              (model.Country === "Iran" &&
                c.BankName &&
                model.BankName &&
                c.BankName.toLowerCase().trim() === model.BankName.toLowerCase().trim())) &&
            c.AccountNumber &&
            model.AccountNumber &&
            c.AccountNumber.toLowerCase().trim() === model.AccountNumber.toLowerCase().trim()
        );
        if (account) {
          findAccount = account;
        }
      }
      if (findAccount) {
        store.dispatch("showErrorMessage", ProjectMessages.bankDuplicateError);
      } else {
        var bankResult = await PaymentMethodService.saveTeammemberBankAccount(model);
        if (bankResult) {
          this.bankList = bankResult;
        }
      }
    },
    async saveWiseAccount(model: any) {
      var findAccount = null as WiseAccount | null;
      if (!model.Id) {
        var account = this.wiseList.find(
          (c) =>
            c.AccountNumber &&
            c.SwiftCode &&
            ((c.AccountNumber && model.AccountNumber && c.AccountNumber.toLowerCase().trim() === model.AccountNumber.toLowerCase().trim()) ||
              (c.SwiftCode && model.SwiftCode && c.SwiftCode.toLowerCase().trim() === model.SwiftCode.toLowerCase().trim()))
        );
        if (account) {
          findAccount = account;
        }
      }
      if (findAccount) {
        store.dispatch("showErrorMessage", ProjectMessages.wiseDuplicateError);
      } else {
        var wiseResult = await PaymentMethodService.saveTeammemberWiseAccount(model);
        if (wiseResult) {
          this.wiseList = wiseResult;
        }
      }
    },
    async savePaypal(model: any) {
      var findPaypal = null as Paypal | null;
      if (!model.Id) {
        var paypal = this.paypalList.find((c) => c.Email && model.Email && c.Email.toLowerCase().trim() === model.Email.toLowerCase().trim());
        if (paypal) {
          findPaypal = paypal;
        }
      }
      if (findPaypal) {
        store.dispatch("showErrorMessage", ProjectMessages.paypalDuplicateError);
      } else {
        var paypalResult = await PaymentMethodService.saveTeammemberPaypal(model);
        if (paypalResult) {
          this.paypalList = paypalResult;
        }
      }
    },
    async save(model: any) {
      try {
        this.loading = true;
        if (model) {
          if (this.teamMemberInfo.PaymentMethodType != this.paymentType) {
            this.teamMemberInfo.PaymentMethodType = this.paymentType;
            this.teamMemberInfo.UpdatedPaymentMethodDate = new Date();
            this.startDate = this.teamMemberInfo.UpdatedPaymentMethodDate;
            this.endDate = this.getEndDate(this.startDate);
            this.isReadonly = true;
          }
        }
        switch (this.paymentType) {
          case PaymentMethods.CryptoCurrency:
            await this.saveCryproCurrency(model);
            break;
          case PaymentMethods.BankAccount:
            await this.saveBankAccount(model);
            break;
          case PaymentMethods.WiseAccount:
            await this.saveWiseAccount(model);
            break;
          case PaymentMethods.Paypal:
            await this.savePaypal(model);
            break;
          default:
            break;
        }
        this.setTeamMemberInfo(this.teamMemberInfo);
      } finally {
        this.loading = false;
      }
    },
    async fecthCryptoCurrencies() {
      var cryptoResult = await PaymentMethodService.getTeammemberCryptoCurrency();
      if (cryptoResult) {
        this.cryptoList = cryptoResult;
      }
    },
    async fecthBankAccounts() {
      var bankResult = await PaymentMethodService.getTeammemberBankAccount();
      if (bankResult) {
        this.bankList = bankResult;
      }
    },
    async fecthWiseAccounts() {
      var wiseResult = await PaymentMethodService.getTeammemberWiseAccount();
      if (wiseResult) {
        this.wiseList = wiseResult;
      }
    },
    async fecthPaypals() {
      var paypalResult = await PaymentMethodService.getTeammemberPaypal();
      if (paypalResult) {
        this.paypalList = paypalResult;
      }
    },
    async fetchPaymentMethod() {
      this.loading = true;
      switch (this.paymentType) {
        case PaymentMethods.CryptoCurrency:
          this.fecthCryptoCurrencies();
          break;
        case PaymentMethods.BankAccount:
          this.fecthBankAccounts();

          break;
        case PaymentMethods.WiseAccount:
          this.fecthWiseAccounts();

          break;
        case PaymentMethods.Paypal:
          this.fecthPaypals();

          break;
        default:
          break;
      }
      this.loading = false;
    },
  },
  async mounted() {
    this.teamMemberInfo = store.state.teamMemberInfo as Teammember;
    if (this.teamMemberInfo.PaymentMethodType) {
      this.paymentType = this.teamMemberInfo.PaymentMethodType;
      this.startDate = this.teamMemberInfo.UpdatedPaymentMethodDate;
      if (this.startDate) {
        this.endDate = this.getEndDate(this.startDate);
        var nowDate = new Date();
        if (this.endDate && this.endDate <= nowDate) {
          this.isReadonly = false;
        }
      }
    }

    this.activePayrun = (await payRunApi.getActivePayruns()).data;
  },
});
</script>
